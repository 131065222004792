import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import Image from "components/image";
import TOC from "components/toc";
import SaleLoadable from "components/saleLoadable";
import Socials from "components/socials";
import AccentBox from "components/accentBox";
import { graphql } from "gatsby";
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "mouse"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    mouse: file(relativePath: { eq: "keymouse/mouse.PNG"}) { ...normalImg },
    mousebutton: file(relativePath: { eq: "keymouse/mousebutton.PNG"}) { ...normalImg },
    elecom_soft2: file(relativePath: { eq: "keymouse/elecom_soft2.png"}) { ...normalImg },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`マウスの選び方`}</h1>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="mouse" alt="パソコンの選び方" mdxType="Image" />
    <p>{`マウスに関するトラブルは、光沢のある面で使用できなかった、手首に負荷がかかり腱鞘炎になった、急に無線がつながらなくなったなど様々である。`}</p>
    <p>{`また、ただ左右のクリックと移動のためだけに右手を使用するのはもったいなく、キーボードと同様最大限キーを利用して生産性を高めていきたいものである。`}</p>
    <p>{`本ページでは、トラブルを避け、生産性を高めることができるマウスの選び方を使用シーンを踏まえ説明する。`}</p>
    <SaleLoadable title="現在のパソコンセール・お買い得情報" mdxType="SaleLoadable" /><br />
    <TOC {...props} mdxType="TOC" />
    <h2 {...{
      "id": "光沢のある面、ガラス面、白い面で使う時はレーザー式かBLUE-LEDマウスを使え",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E5%85%89%E6%B2%A2%E3%81%AE%E3%81%82%E3%82%8B%E9%9D%A2%E3%80%81%E3%82%AC%E3%83%A9%E3%82%B9%E9%9D%A2%E3%80%81%E7%99%BD%E3%81%84%E9%9D%A2%E3%81%A7%E4%BD%BF%E3%81%86%E6%99%82%E3%81%AF%E3%83%AC%E3%83%BC%E3%82%B6%E3%83%BC%E5%BC%8F%E3%81%8BBLUE-LED%E3%83%9E%E3%82%A6%E3%82%B9%E3%82%92%E4%BD%BF%E3%81%88",
        "aria-label": "光沢のある面、ガラス面、白い面で使う時はレーザー式かBLUE LEDマウスを使え permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`光沢のある面、ガラス面、白い面で使う時はレーザー式かBLUE LEDマウスを使え`}</h2>
    <p>{`マウスの読み込み方式は大きく分けて`}<strong parentName="p">{`光学式(RED LED)、IR LED、レーザー式、Blue LED`}</strong>{`
**の４つが存在する。４つともマウスの位置変化を捉える原理は同じである。`}</p>
    <p>{`まずマウスの下から光を出し、光の跳ね返りをイメージセンサーで捉えてイメージを記憶していく。マウスの移動に伴い面の模様や凹凸が変わるため、異なったイメージが記録される。これら少しずつ変化するイメージからマウスの移動量と方向を計算する、という仕組みである。`}</p>
    <p>{`４つの方式の違いはデスクに照射する光の性質だけであり、`}<strong parentName="p">{`全て光学式マウス`}</strong>{`であるが、慣習的に最初に発売された`}<strong parentName="p">{`赤いLEDを用いたマウスのみを単に光学式マウスと呼び、赤外線を用いたものをIR LEDマウス、レーザーを用いたものをレーザー式マウス、青色LEDを用いたものをBlue LEDマウスと呼んでいる。`}</strong>{`。`}</p>
    <p>{`ただし`}<strong parentName="p">{`赤色LED以外の方式を用いている場合であっても単に光学式と表記される場合もある`}</strong>{`ため注意が必要である。`}</p>
    <AccentBox title="各読み取り方式によるマウスの特徴" mdxType="AccentBox">
  <li><span className="bold">光学式マウス(赤色LED)</span>・・・光沢面、ガラス面、白い面が苦手だが安価。パソコンを購入したときに付属する一般的なマウス。</li>
  <li><span className="bold">IR LED</span>・・・光学式マウスと同様の性質であるが、赤外線は低消費電力であり<span className="red">電池１本で２年間以上</span>持つ。不可視光のためマウスの下から光は見えない。</li>
  <li><span className="bold">レーザー式</span>・・・ガラス面は製品によりけりだが、ほぼあらゆる面での使用が可能。価格は高め。不可視光。細かい動きを読み取る能力(解像度)が高く、ゲーミングマウスとしての製品も多い。</li>
  <li><span className="bold">Blue LED</span>・・・<span className="red">あらゆる面で使用が可能</span></li>
    </AccentBox>
    <p><strong parentName="p">{`場所を選ばず使いたいならばレーザー式かBlue LED方式`}</strong>{`のマウスを購入しよう。また`}<strong parentName="p">{`電池のことを極力考えたくない人はIR LEDマウス`}</strong>{`がおすすめである。`}</p>
    <p><strong parentName="p">{`IR LED以外のマウスであっても１年間近くは電池が持つ`}</strong>{`ためそれほど神経質になる必要はないかもしれない。他の条件と合わせて統合的に判断しよう。`}</p>
    <h2 {...{
      "id": "握りやすさとスライドのしやすさを考えて選べ。",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E6%8F%A1%E3%82%8A%E3%82%84%E3%81%99%E3%81%95%E3%81%A8%E3%82%B9%E3%83%A9%E3%82%A4%E3%83%89%E3%81%AE%E3%81%97%E3%82%84%E3%81%99%E3%81%95%E3%82%92%E8%80%83%E3%81%88%E3%81%A6%E9%81%B8%E3%81%B9%E3%80%82",
        "aria-label": "握りやすさとスライドのしやすさを考えて選べ。 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`握りやすさとスライドのしやすさを考えて選べ。`}</h2>
    <p>{`人によって握り方の癖や手の大きさが異なるため、一概にどのマウスが握りやすいかということは言えない。`}</p>
    <p>{`しかし一般的に左右対称のマウスよりは`}<strong parentName="p">{`右手の親指側が少しくぼんでいるマウスの方が握り安く`}</strong>{`、エルゴノミクスマウスと呼ばれる種類のマウスや、握りやすさをうたい文句としたマウスは必ずその形となっている。`}</p>
    <p>{`ただし見落とされがちなのだが、握りやすいだけではなく動かしやすさも重要である。`}</p>
    <p>{`動かしやすさには重量が大きく関係しており、`}<strong parentName="p">{`重いマウスを使うとその分動かしにくく感じる`}</strong>{`。`}<a href="https://amzn.to/2ETvIuY" target="_blank">{`エルゴノミクスマウス`}</a>{`は非常に握りやすく、腕の体勢も楽であるが、重量があるものが多く使いづらさも感じるため評判をよく読んで判断すべきであろう。`}</p>
    <p>{`店舗で触れるときは握ってみる事とともに必ず動かしてみて感触を掴むことをおすすめする。`}</p>
    <h2 {...{
      "id": "作業効率をアップさせたいならばボタン数が多いものを",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E4%BD%9C%E6%A5%AD%E5%8A%B9%E7%8E%87%E3%82%92%E3%82%A2%E3%83%83%E3%83%97%E3%81%95%E3%81%9B%E3%81%9F%E3%81%84%E3%81%AA%E3%82%89%E3%81%B0%E3%83%9C%E3%82%BF%E3%83%B3%E6%95%B0%E3%81%8C%E5%A4%9A%E3%81%84%E3%82%82%E3%81%AE%E3%82%92",
        "aria-label": "作業効率をアップさせたいならばボタン数が多いものを permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`作業効率をアップさせたいならばボタン数が多いものを`}</h2>
    <p>{`パソコン購入時に付属するマウスは一般的に左右のボタンと中央のスクローリングホイール以外にインプットできる箇所がない。これで十分かもしれないが、より多くのボタンがあるマウスを使いこなすことができると、それだけ作業効率を高められることは間違いなしである。`}</p>
    <p>{`ここでは多機能マウスにおいて抑えておくべきポイントを述べたい。`}</p>
    <Image {...props} name="mousebutton" alt="複数ボタンがあるマウス" mdxType="Image" />
    <h3 {...{
      "id": "チルトホイールによる横移動は可能か",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%81%E3%83%AB%E3%83%88%E3%83%9B%E3%82%A4%E3%83%BC%E3%83%AB%E3%81%AB%E3%82%88%E3%82%8B%E6%A8%AA%E7%A7%BB%E5%8B%95%E3%81%AF%E5%8F%AF%E8%83%BD%E3%81%8B",
        "aria-label": "チルトホイールによる横移動は可能か permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`チルトホイールによる横移動は可能か`}</h3>
    <p>{`チルトとは英語でtilt、傾けるという意味であり、チルトホイールはスクローリング以外にも傾けることによって操作を行うことができる。`}</p>
    <p>{`一般的には横スクロールが割り当てられており、Excel表で横に行くときや、Webサイトの幅が広く、横が見えない時などに使用する。`}</p>
    <h3 {...{
      "id": "進む・戻るボタンがあるか",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E9%80%B2%E3%82%80%E3%83%BB%E6%88%BB%E3%82%8B%E3%83%9C%E3%82%BF%E3%83%B3%E3%81%8C%E3%81%82%E3%82%8B%E3%81%8B",
        "aria-label": "進む・戻るボタンがあるか permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`進む・戻るボタンがあるか`}</h3>
    <p>{`グーグルで検索をしてWebサイトを閲覧し、あまり良くなかったので他のページを閲覧する時など、戻るボタンのニーズは非常に高い。`}</p>
    <p>{`わざわざ戻るボタンにマウスポインタを持っていきおすのと、マウスの戻るボタンを押すのでは大きく効率が異なる。これは生産性を考えるのならば必ずつけておきたい機能である。`}</p>
    <h3 {...{
      "id": "ファンクションキーのショートカットで効率化",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%95%E3%82%A1%E3%83%B3%E3%82%AF%E3%82%B7%E3%83%A7%E3%83%B3%E3%82%AD%E3%83%BC%E3%81%AE%E3%82%B7%E3%83%A7%E3%83%BC%E3%83%88%E3%82%AB%E3%83%83%E3%83%88%E3%81%A7%E5%8A%B9%E7%8E%87%E5%8C%96",
        "aria-label": "ファンクションキーのショートカットで効率化 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ファンクションキーのショートカットで効率化`}</h3>
    <p>{`チルトホイール、進む・戻るボタンの他にもボタンがついているマウスもある。このタイプは`}<strong parentName="p">{`自分のよく使う操作をショートカットとして割り当てることが可能`}</strong>{`でタブの移動、文字の拡大縮小など、様々なボタンの組み合わせを登録することができる。`}</p>
    <p>{`エレコムのマウスアシスタントソフトを使用している例を下に示す。`}</p>
    <Image {...props} name="elecom_soft2" alt="マウス挙動の設定ソフト" mdxType="Image" />
    <h3 {...{
      "id": "DPI設定変更ボタン",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#DPI%E8%A8%AD%E5%AE%9A%E5%A4%89%E6%9B%B4%E3%83%9C%E3%82%BF%E3%83%B3",
        "aria-label": "DPI設定変更ボタン permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`DPI設定変更ボタン`}</h3>
    <p>{`マウスの移動速度を調整するためのボタンである。ボタンを押すたびにマウスを移動したときのポインタの移動距離を変更することができる。５段階程度調整できるものが一般的。`}</p>
    <p>{`DPIはDots Per Inchの略で、マウスを１インチすなわち2.5センチメートル動かしたときに動くドット数のことを言う。`}</p>
    <p>{`たとえば、FullHDのモニタならば横が1920ドットであるため、DPIが960の設定だと2インチマウスを動かすと端から端まで到達することになる。`}</p>
    <p>{`ゲーミングマウスに主に搭載されるボタンだが、特にPUBGなどのFPSゲームを行う場合に重宝する。使用シーンとしてはスナイパーライフルで敵を打ち落としたいような場合、より厳密な狙い撃ちができるようDPIを落とすなどである。`}</p>
    <h2 {...{
      "id": "コードが気にならなければ有線も良い",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%82%B3%E3%83%BC%E3%83%89%E3%81%8C%E6%B0%97%E3%81%AB%E3%81%AA%E3%82%89%E3%81%AA%E3%81%91%E3%82%8C%E3%81%B0%E6%9C%89%E7%B7%9A%E3%82%82%E8%89%AF%E3%81%84",
        "aria-label": "コードが気にならなければ有線も良い permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`コードが気にならなければ有線も良い`}</h2>
    <p>{`無線マウスが主流となってはいるが、`}<strong parentName="p">{`有線は電池が不要な他、動作が安定し反応速度も速い、単純な作りで故障しにくい`}</strong>{`というメリットがある。`}</p>
    <p>{`動作の安定性上ゲーミングマウスの多くは実際有線接続となっている。`}</p>
    <p>{`無線接続も基本的に安定しているが、他のワイヤレス機器との干渉や、電波の到達距離が短くなるスチール製の机などの金属面の使用などで正常に動かなくなる可能性がある。`}</p>
    <p>{`また電池の減少により動作が不安定になることもある。`}</p>
    <p>{`有線か無線かは無線のデメリットにも目を向けて考える必要がある。ただ、Youtubeを離れてみたい時など、`}<strong parentName="p">{`遠隔で操作したい場合は有線では届かないので無線一択`}</strong>{`である。`}</p>
    <h2 {...{
      "id": "電池の伸びと軽量化をリチウムイオン電池で実現",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E9%9B%BB%E6%B1%A0%E3%81%AE%E4%BC%B8%E3%81%B3%E3%81%A8%E8%BB%BD%E9%87%8F%E5%8C%96%E3%82%92%E3%83%AA%E3%83%81%E3%82%A6%E3%83%A0%E3%82%A4%E3%82%AA%E3%83%B3%E9%9B%BB%E6%B1%A0%E3%81%A7%E5%AE%9F%E7%8F%BE",
        "aria-label": "電池の伸びと軽量化をリチウムイオン電池で実現 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`電池の伸びと軽量化をリチウムイオン電池で実現`}</h2>
    <p>{`無線マウスの最大の弱点は電池を交換しなければならないことであるが、この弱点は長寿命のリチウムイオン型電池を使用することにより克服できる。`}</p>
    <p>{`リチウムイオン電池は電子機器に使われる場合、ボタン型が主であるが、単3、単4型も販売されている。`}<a parentName="p" {...{
        "href": "https://amzn.to/2CNV6kv",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`Energizer(エナジャイザー)`}</a>{`が最も有名。アルカリ電池と比べて容量あたりの単価が割高なものの、`}<strong parentName="p">{`通常のアルカリ電池の数倍の寿命`}</strong>{`が期待できる。`}</p>
    <p>{`アルカリ乾電池で1年持つとすると、3年程度の寿命が期待してもよく、下手をすると製品寿命に匹敵する可能性もある。`}</p>
    <p><strong parentName="p">{`無線マウスだとやや重く感じる問題も同時に解決できる`}</strong>{`。単3のアルカリ乾電池は約25gであるが、リチウムイオンの単3乾電池は約15gで`}<strong parentName="p">{`10g程度軽くなる`}</strong>{`。100g程度のマウスが多いので10パーセント軽くなることになる。`}</p>
    <p>{`単四電池を`}<a parentName="p" {...{
        "href": "https://amzn.to/2BXFwkw",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`スペーサー`}</a>{`を用いて単三電池へ変換して用いると更に軽くなり、電池寿命は短くなるのが難点であるが、家にあるもので試したところ`}<strong parentName="p">{`11gまで軽量化できた`}</strong>{`。`}</p>
    <p>{`有線マウスと比べて無線マウスはややズッシリと感じるが、リチウムイオン電池を使用することにより、有線と同等の軽いタッチで操作できるようになるので重いと思った時は試してみよう。`}</p>
    <h2 {...{
      "id": "その他注意すべき点",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%81%9D%E3%81%AE%E4%BB%96%E6%B3%A8%E6%84%8F%E3%81%99%E3%81%B9%E3%81%8D%E7%82%B9",
        "aria-label": "その他注意すべき点 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`その他注意すべき点`}</h2>
    <p>{`その他注意しなければならない点について簡単にまとめておく。`}</p>
    <h3 {...{
      "id": "静音性",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E9%9D%99%E9%9F%B3%E6%80%A7",
        "aria-label": "静音性 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`静音性`}</h3>
    <p>{`ボタンを押したときにカチッ、カチッと音が鳴るかどうか。一般的なマウスはこのような音がなるが、静音性マウスだと低め響きにくい音に変わる。`}</p>
    <p>{`使用感としては音がカチッ、カチッっと鳴る方が気持ちが良いが、周囲を気にする必要がある場合は静音性に優れたマウスを使用しよう。`}</p>
    <h3 {...{
      "id": "Bluetooth接続",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#Bluetooth%E6%8E%A5%E7%B6%9A",
        "aria-label": "Bluetooth接続 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Bluetooth接続`}</h3>
    <p>{`無線接続のタイプにはUSBレシーバーを使用するタイプか、Bluetooth接続を行うタイプの２種類がある。`}</p>
    <p>{`Bluetoothタイプは使用する機器がBluetoothに対応している場合に使用でき、ノートパソコンで主に使用できる。USB端子の数がノートパソコンに少ない場合には選択の余地があるかもしれない。`}</p>
    <p>{`手間に関して言うと、`}<strong parentName="p">{`USBレシーバータイプはUSBを挿す必要はあるが、挿すだけでマウスの認識を行ってくれる`}</strong>{`。一方でBluetoothはペアリングという作業が必要となり、初回接続時にはいくらかパソコン上での操作が必要となる。`}</p>
    <p>{`甲乙はつけがたいが、個人的にはUSBレシーバータイプの方が好みである。`}</p>
    <h2 {...{
      "id": "おすすめマウス",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%81%8A%E3%81%99%E3%81%99%E3%82%81%E3%83%9E%E3%82%A6%E3%82%B9",
        "aria-label": "おすすめマウス permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`おすすめマウス`}</h2>
    <p>{`現状私の家で使用しているものは上の写真でも登場しているが`}<a parentName="p" {...{
        "href": "https://amzn.to/3qYpcrW",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`エレコムの握りの極み`}</a>{`というワイヤレスマウスである。`}</p>
    <p>{`ボタンには進む、戻るの他、タブを移動する、タブを閉じるを割り当てておりWebサイトの閲覧が楽に行える。また非常に握りやすい。`}</p>
    <p>{`難点としては電池を入れると112gとやや重いことであったが、単四リチウムイオン電池とスペーサーを採用することで100gまで軽量化でき、とても快適に使えている。`}</p>
    <p>{`また、USBレシーバータイプではあるが、マウス内に収納するスペースを持っているため持ち運びも便利である。（着脱式レシーバー)`}</p>
    <p>{`効率を高めたい人へはおすすめである。`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      